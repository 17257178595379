<template>
  <div>
  </div>
</template>
<script>
export default {
  mounted(){
    /** 移除新类别红点 */
    this.$store.commit('set_new_template', false);
  }
}
</script>
